export async function getPlayerData(session, allyCode, displayMessage) {
  if(session && allyCode) {
    let body = {
      payload: {
        allyCode: allyCode
      },
      session: session
    }
    let player = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/api/player`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    })
    if(player.ok) {
      let account = await player.json()
      return account
    } else {
      let error = await player.text()
      displayMessage(error, false)
      return {}
    }
  }
}

export async function getPlayerGACHistory(session, allyCode, displayMessage) {
    if(session !== '' && allyCode !== '' && allyCode !== undefined) {
        let body = {
            session: session,
            allyCode: allyCode
        }
        let response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/api/player/gac`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(body)
        })
        if(response.ok) {
            let gacList = await response.json()
            return gacList
        } else {
            let error = await response.text()
            displayMessage(error, false)
            return []
        }
    }
}

export async function getGameConnectionCount(session, allyCode) {
  if(session !== '' && allyCode !== '' && allyCode !== undefined) {
    let body = {
        session: session,
        allyCode: allyCode
    }
    let response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/api/player/gameConnection`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    })
    if(response.ok) {
        let count = await response.json()
        return count
    } else {
        let error = await response.text()
        console.log(error)
        return {}
    }
}
}

export async function getCurrentGACBoard(session, allyCode) {
  if(session !== '' && allyCode !== '' && allyCode !== undefined) {
    let body = {
        session: session,
        allyCode: allyCode
    }
    let response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/api/player/gac/board`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    })
    if(response.ok) {
        let board = await response.json()
        return board
    } else {
        let error = await response.text()
        console.log(error)
        return {}
    }
}
}

export async function saveGac(session, activeGac, activeGacId, displayMessage, displaySuccess = true) {
  if(activeGacId !== '' && session !== '' && activeGac?.player?.allyCode) {
    let gacToPost = JSON.parse(JSON.stringify(activeGac))
    gacToPost.time = Date.now()
    let body = {
        session: session,
        id: activeGacId,
        gac: gacToPost
    }
    let response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/api/player/gac/add`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    })
    if(response.ok) {
        let gacId = await response.text()
        if(displaySuccess) displayMessage('Successfully saved GAC data.', true)
        return gacId
    } else {
        let error = await response.text()
        console.log(error)
        displayMessage('Unable to save GAC data.', false)
        return ''
    }
  }
}

export async function getLatestBracketResults(session, allyCode, displayMessage) {
  let body = {
    session: session,
    allyCode: allyCode
  }
  let response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/api/player/gac/review`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(body)
  })
  if(response.ok) {
    let results = response.json()
    return results
  } else {
    let error = await response.text()
    console.log(error)
    displayMessage('Unable to retrieve latest bracket results.', false)
    return {}
  }
}