export const stats = {
    "1":{
      "statId":1,
      "nameKey":"UnitStat_Health",
      "descKey":"UnitStatDescription_Health_TU7",
      "isDecimal":false,
      "name":"Health",
      "detailedName":"Max Health"
    },
    "2":{
      "statId":2,
      "nameKey":"UnitStat_Strength",
      "descKey":"UnitStatDescription_Strength",
      "isDecimal":false,
      "name":"Strength",
      "detailedName":"Strength"
    },
    "3":{
      "statId":3,
      "nameKey":"UnitStat_Agility",
      "descKey":"UnitStatDescription_Agility",
      "isDecimal":false,
      "name":"Agility",
      "detailedName":"Agility"
    },
    "4":{
      "statId":4,
      "nameKey":"UnitStat_Intelligence_TU7",
      "descKey":"UnitStatDescription_Intelligence",
      "isDecimal":false,
      "name":"Tactics",
      "detailedName":"Tactics"
    },
    "5":{
      "statId":5,
      "nameKey":"UnitStat_Speed",
      "descKey":"UnitStatDescription_Speed",
      "isDecimal":false,
      "name":"Speed",
      "detailedName":"Speed"
    },
    "6":{
      "statId":6,
      "nameKey":"UnitStat_AttackDamage",
      "descKey":"UnitStatDescription_AttackDamage",
      "isDecimal":false,
      "name":"Physical Damage",
      "detailedName":"Physical Damage"
    },
    "7":{
      "statId":7,
      "nameKey":"UnitStat_AbilityPower",
      "descKey":"UnitStatDescription_AbilityPower",
      "isDecimal":false,
      "name":"Special Damage",
      "detailedName":"Special Damage"
    },
    "8":{
      "statId":8,
      "nameKey":"UnitStat_Armor",
      "descKey":"UnitStatDescription_Armor",
      "isDecimal":false,
      "name":"Armor",
      "detailedName":"Armor"
    },
    "9":{
      "statId":9,
      "nameKey":"UnitStat_Suppression",
      "descKey":"UnitStatDescription_Suppression",
      "isDecimal":false,
      "name":"Resistance",
      "detailedName":"Resistance"
    },
    "10":{
      "statId":10,
      "nameKey":"UnitStat_ArmorPenetration",
      "descKey":"UnitStatDescription_ArmorPenetration",
      "isDecimal":false,
      "name":"Armor Penetration",
      "detailedName":"Armor Penetration"
    },
    "11":{
      "statId":11,
      "nameKey":"UnitStat_SuppressionPenetration",
      "descKey":"UnitStatDescription_SuppressionPenetration",
      "isDecimal":false,
      "name":"Resistance Penetration",
      "detailedName":"Resistance Penetration"
    },
    "12":{
      "statId":12,
      "nameKey":"UnitStat_DodgeRating_TU5V",
      "descKey":"UnitStatDescription_DodgeRating",
      "isDecimal":false,
      "name":"Dodge Chance",
      "detailedName":"Dodge Rating"
    },
    "13":{
      "statId":13,
      "nameKey":"UnitStat_DeflectionRating_TU5V",
      "descKey":"UnitStatDescription_DeflectionRating",
      "isDecimal":false,
      "name":"Deflection Chance",
      "detailedName":"Deflection Rating"
    },
    "14":{
      "statId":14,
      "nameKey":"UnitStat_AttackCriticalRating_TU5V",
      "descKey":"UnitStatDescription_AttackCriticalRating",
      "isDecimal":false,
      "name":"Physical Critical Chance",
      "detailedName":"Physical Critical Rating"
    },
    "15":{
      "statId":15,
      "nameKey":"UnitStat_AbilityCriticalRating_TU5V",
      "descKey":"UnitStatDescription_AbilityCriticalRating",
      "isDecimal":false,
      "name":"Special Critical Chance",
      "detailedName":"Special Critical Rating"
    },
    "16":{
      "statId":16,
      "nameKey":"UnitStat_CriticalDamage",
      "descKey":"UnitStatDescription_CriticalDamage",
      "isDecimal":true,
      "name":"Critical Damage",
      "detailedName":"Critical Damage"
    },
    "17":{
      "statId":17,
      "nameKey":"UnitStat_Accuracy",
      "descKey":"UnitStatDescription_Accuracy",
      "isDecimal":true,
      "name":"Potency",
      "detailedName":"Potency"
    },
    "18":{
      "statId":18,
      "nameKey":"UnitStat_Resistance",
      "descKey":"UnitStatDescription_Resistance",
      "isDecimal":true,
      "name":"Tenacity",
      "detailedName":"Tenacity"
    },
    "19":{
      "statId":19,
      "nameKey":"UnitStat_DodgePercentAdditive",
      "descKey":"",
      "isDecimal":true,
      "name":"Dodge",
      "detailedName":"Dodge Percent Additive"
    },
    "20":{
      "statId":20,
      "nameKey":"UnitStat_DeflectionPercentAdditive",
      "descKey":"",
      "isDecimal":true,
      "name":"Deflection",
      "detailedName":"Deflection Percent Additive"
    },
    "21":{
      "statId":21,
      "nameKey":"UnitStat_AttackCriticalPercentAdditive",
      "descKey":"",
      "isDecimal":true,
      "name":"Physical Critical Chance",
      "detailedName":"Physical Critical Percent Additive"
    },
    "22":{
      "statId":22,
      "nameKey":"UnitStat_AbilityCriticalPercentAdditive",
      "descKey":"",
      "isDecimal":true,
      "name":"Special Critical Chance",
      "detailedName":"Special Critical Percent Additive"
    },
    "23":{
      "statId":23,
      "nameKey":"UnitStat_ArmorPercentAdditive",
      "descKey":"",
      "isDecimal":true,
      "name":"Armor",
      "detailedName":"Armor Percent Additive"
    },
    "24":{
      "statId":24,
      "nameKey":"UnitStat_SuppressionPercentAdditive",
      "descKey":"",
      "isDecimal":true,
      "name":"Resistance",
      "detailedName":"Resistance Percent Additive"
    },
    "25":{
      "statId":25,
      "nameKey":"UnitStat_ArmorPenetrationPercentAdditive",
      "descKey":"",
      "isDecimal":true,
      "name":"Armor Penetration",
      "detailedName":"Armor Penetration Percent Additive"
    },
    "26":{
      "statId":26,
      "nameKey":"UnitStat_SuppressionPenetrationPercentAdditive",
      "descKey":"",
      "isDecimal":true,
      "name":"Resistance Penetration",
      "detailedName":"Resistance Penetration Percent Additive"
    },
    "27":{
      "statId":27,
      "nameKey":"UnitStat_HealthSteal",
      "descKey":"UnitStatDescription_HealthSteal",
      "isDecimal":true,
      "name":"Health Steal",
      "detailedName":"Health Steal"
    },
    "28":{
      "statId":28,
      "nameKey":"UnitStat_MaxShield",
      "descKey":"UnitStatDescription_MaxShield",
      "isDecimal":false,
      "name":"Protection",
      "detailedName":"Max Protection"
    },
    "29":{
      "statId":29,
      "nameKey":"UnitStat_ShieldPenetration",
      "descKey":"",
      "isDecimal":true,
      "name":"Protection Ignore",
      "detailedName":"Protection Ignore"
    },
    "30":{
      "statId":30,
      "nameKey":"UnitStat_HealthRegen",
      "descKey":"",
      "isDecimal":true,
      "name":"Health Regeneration",
      "detailedName":"Health Regen"
    },
    "31":{
      "statId":31,
      "nameKey":"UnitStat_AttackDamagePercentAdditive",
      "descKey":"",
      "isDecimal":true,
      "name":"Physical Damage",
      "detailedName":"Physical Damage Percent Additive"
    },
    "32":{
      "statId":32,
      "nameKey":"UnitStat_AbilityPowerPercentAdditive",
      "descKey":"",
      "isDecimal":true,
      "name":"Special Damage",
      "detailedName":"Special Damage Percent Additive"
    },
    "33":{
      "statId":33,
      "nameKey":"UnitStat_DodgeNegatePercentAdditive",
      "descKey":"",
      "isDecimal":true,
      "name":"Physical Accuracy",
      "detailedName":"Dodge Negate Percent Additive"
    },
    "34":{
      "statId":34,
      "nameKey":"UnitStat_DeflectionNegatePercentAdditive",
      "descKey":"",
      "isDecimal":true,
      "name":"Special Accuracy",
      "detailedName":"Deflection Negate Percent Additive"
    },
    "35":{
      "statId":35,
      "nameKey":"UnitStat_AttackCriticalNegatePercentAdditive",
      "descKey":"",
      "isDecimal":true,
      "name":"Physical Critical Avoidance",
      "detailedName":"Physical Critical Negate Percent Additive"
    },
    "36":{
      "statId":36,
      "nameKey":"UnitStat_AbilityCriticalNegatePercentAdditive",
      "descKey":"",
      "isDecimal":true,
      "name":"Special Critical Avoidance",
      "detailedName":"Special Critical Negate Percent Additive"
    },
    "37":{
      "statId":37,
      "nameKey":"UnitStat_DodgeNegateRating",
      "descKey":"UnitStatDescription_DodgeNegateRating",
      "isDecimal":false,
      "name":"Physical Accuracy",
      "detailedName":"Dodge Negate Rating"
    },
    "38":{
      "statId":38,
      "nameKey":"UnitStat_DeflectionNegateRating",
      "descKey":"UnitStatDescription_DeflectionNegateRating",
      "isDecimal":false,
      "name":"Special Accuracy",
      "detailedName":"Deflection Negate Rating"
    },
    "39":{
      "statId":39,
      "nameKey":"UnitStat_AttackCriticalNegateRating",
      "descKey":"UnitStatDescription_AttackCriticalNegateRating",
      "isDecimal":false,
      "name":"Physical Critical Avoidance",
      "detailedName":"Physical Critical Negate Rating"
    },
    "40":{
      "statId":40,
      "nameKey":"UnitStat_AbilityCriticalNegateRating",
      "descKey":"UnitStatDescription_AbilityCriticalNegateRating",
      "isDecimal":false,
      "name":"Special Critical Avoidance",
      "detailedName":"Special Critical Negate Rating"
    },
    "41":{
      "statId":41,
      "nameKey":"UnitStat_Offense",
      "descKey":"UnitStatDescription_Offense",
      "isDecimal":false,
      "name":"Offense",
      "detailedName":"Offense"
    },
    "42":{
      "statId":42,
      "nameKey":"UnitStat_Defense",
      "descKey":"UnitStatDescription_Defense",
      "isDecimal":false,"name":"Defense",
      "detailedName":"Defense"
    },
    "43":{
      "statId":43,
      "nameKey":"UnitStat_DefensePenetration",
      "descKey":"UnitStatDescription_DefensePenetration",
      "isDecimal":false,
      "name":"Defense Penetration",
      "detailedName":"Defense Penetration"
    },
    "44":{
      "statId":44,
      "nameKey":"UnitStat_EvasionRating",
      "descKey":"UnitStatDescription_EvasionRating",
      "isDecimal":false,
      "name":"Evasion",
      "detailedName":"Evasion Rating"
    },
    "45":{
      "statId":45,
      "nameKey":"UnitStat_CriticalRating",
      "descKey":"UnitStatDescription_CriticalRating",
      "isDecimal":false,
      "name":"Critical Chance",
      "detailedName":"Critical Rating"
    },
    "46":{
      "statId":46,
      "nameKey":"UnitStat_EvasionNegateRating",
      "descKey":"UnitStatDescription_EvasionNegateRating",
      "isDecimal":false,
      "name":"Accuracy",
      "detailedName":"Evasion Negate Rating"
    },
    "47":{
      "statId":47,
      "nameKey":"UnitStat_CriticalNegateRating",
      "descKey":"UnitStatDescription_CriticalNegateRating",
      "isDecimal":false,
      "name":"Critical Avoidance",
      "detailedName":"Critical Negate Rating"
    },
    "48":{
      "statId":48,
      "nameKey":"UnitStat_OffensePercentAdditive",
      "descKey":"",
      "isDecimal":true,
      "name":"Offense",
      "detailedName":"Offense Percent Additive"
    },
    "49":{
      "statId":49,
      "nameKey":"UnitStat_DefensePercentAdditive",
      "descKey":"",
      "isDecimal":true,
      "name":"Defense",
      "detailedName":"Defense Percent Additive"
    },
    "50":{
      "statId":50,
      "nameKey":"UnitStat_DefensePenetrationPercentAdditive",
      "descKey":"",
      "isDecimal":true,
      "name":"Defense Penetration",
      "detailedName":"Defense Penetration Percent Additive"
    },
    "51":{
      "statId":51,
      "nameKey":"UnitStat_EvasionPercentAdditive",
      "descKey":"",
      "isDecimal":true,
      "name":"Evasion",
      "detailedName":"Evasion Percent Additive"
    },
    "52":{
      "statId":52,
      "nameKey":"UnitStat_EvasionNegatePercentAdditive",
      "descKey":"",
      "isDecimal":true,
      "name":"Accuracy",
      "detailedName":"Evasion Negate Percent Additive"
    },
    "53":{
      "statId":53,
      "nameKey":"UnitStat_CriticalChancePercentAdditive",
      "descKey":"",
      "isDecimal":true,
      "name":"Critical Chance",
      "detailedName":"Critical Chance Percent Additive"
    },
    "54":{
      "statId":54,
      "nameKey":"UnitStat_CriticalNegateChancePercentAdditive",
      "descKey":"",
      "isDecimal":true,
      "name":"Critical Avoidance",
      "detailedName":"Critical Negate Chance Percent Additive"
    },
    "55":{
      "statId":55,
      "nameKey":"UnitStat_MaxHealthPercentAdditive",
      "descKey":"",
      "isDecimal":true,
      "name":"Health",
      "detailedName":"Max Health Percent Additive"
    },
    "56":{
      "statId":56,
      "nameKey":"UnitStat_MaxShieldPercentAdditive",
      "descKey":"",
      "isDecimal":true,
      "name":"Protection",
      "detailedName":"Max Protection Percent Additive"
    },
    "57":{
      "statId":57,
      "nameKey":"UnitStat_SpeedPercentAdditive",
      "descKey":"",
      "isDecimal":true,
      "name":"Speed",
      "detailedName":"Speed Percent Additive"},
    "58":{
      "statId":58,
      "nameKey":"UnitStat_CounterAttackRating",
      "descKey":"",
      "isDecimal":true,
      "name":"Counter Attack",
      "detailedName":"Counter Attack Rating"
    },
    "59":{
      "statId":59,
      "nameKey":"Combat_Buffs_TASK_NAME_2",
      "descKey":"",
      "isDecimal":true,
      "name":"Taunt",
      "detailedName":"Taunt"
    },
    "60":{
      "statId":60,
      "nameKey":"UnitStat_DefensePenetrationTargetPercentAdditive",
      "descKey":"UnitStatDescription_DefensePenetrationTargetPercentAdditive",
      "isDecimal":true,
      "name":"Defense Penetration",
      "detailedName":"Target Defense Penetration Percent Additive"
    },
    "61":{
      "statId":61,
      "nameKey":"UNIT_STAT_STAT_VIEW_MASTERY",
      "descKey":"",
      "isDecimal":true,
      "name":"Mastery",
      "detailedName":"Mastery"
    }
  }

export const BattleOutcome = {
  0: "BattleOutcome_DEFAULT",
  1: "WIN",
  2: "LOSS",
  3: "RETREAT",
  4: "DRAW",
  5: "CLIENTSTOPPED"
}